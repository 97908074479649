import React, { useState, useRef, useEffect } from 'react';
import { Form, Alert, Row, Col } from 'react-bootstrap';
import { Container, Box, Grid, Typography, Button } from '@mui/material';
import './Contact.css';
import logo from '../assets/C_Logo_1.svg';
import nextStepsImage from '../assets/contact.jpeg';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    service: '',
    description: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name) formErrors.name = 'Name is required';
    if (!formData.email) formErrors.email = 'Email is required';
    if (!formData.phone) formErrors.phone = 'Phone is required';
    if (!formData.description) formErrors.description = 'Description is required';

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: JSON.stringify({
          access_key: '7ba90bc7-0140-4e8c-9348-79e74fee2d3f', // Replace with your Web3Forms access key
          ...formData,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setSubmitted(true);
            setFormData({
              name: '',
              companyName: '',
              email: '',
              phone: '',
              service: '',
              description: '',
            });
            setErrors({});
          } else {
            setSubmitted(false);
            alert(data.message);
          }
        })
        .catch((error) => {
          setSubmitted(false);
          console.error('Form submission error', error);
        });
    } else {
      setErrors(formErrors);
      setSubmitted(false);
    }
  };

  const formRef = useRef(null);
  const handleSubscribeSubmit = (event) => {
    event.preventDefault();
    const form = formRef.current;
    const formData = new FormData(form);
    const action = form.action;

    fetch(action, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    }).then(() => {
      form.reset();
      alert('Form submitted successfully!');
    }).catch((error) => {
      console.error('Error:', error);
    });
  };

  return (
    <>
      <Container className="contact-container">
        <Row className="contact-header">
          <Col md={6}>
            <h2>Partner with us for all your Business needs</h2>
            <div className="benefits-list">
              <h4>Our Team Can Help You With:</h4>
              <ul>
                <li><strong>Boosting Your Online Presence: </strong>Tailored digital marketing strategies to engage your audience and grow your brand.</li>
                <li><strong>Innovative IT Solutions: </strong>Custom software and website development for efficient, secure, and scalable tech infrastructure.</li>
                <li><strong>Accurate Financial Insights: </strong>Streamlined bookkeeping and accounting for real-time financial clarity and compliance.</li>
                <li><strong>Sustainable Savings: </strong>Energy savings and optimization strategies to cut costs and reduce your environmental footprint.</li>
                <li><strong>Impactful Branding: </strong>High-quality, custom-designed branding that makes a lasting impression.</li>
              </ul>
              <p><strong>Email us on: info@stratagemixconsulting.com</strong></p>
            </div>
            <div className="process-list">
              <img src={nextStepsImage} alt="Next Steps" className="next-steps-image" />
            </div>
          </Col>
          <Col md={6} className="contact-form">
            <h2>Get in Touch for a Free Consultation</h2>
            {submitted && <Alert variant="success">Form submitted successfully!</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Name <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formLastName">
                <Form.Label>Company name</Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Company email <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formService">
                <Form.Label>How Can We Help You?</Form.Label>
                <Form.Control as="select" name="service" value={formData.service} onChange={handleChange}>
                  <option>Select Option</option>
                  <option>Energy & Resource Optimization</option>
                  <option>IT Services/Website or App Development</option>
                  <option>Digital Marketing</option>
                  <option>Accounting & Bookkeeping</option>
                  <option>Graphic Design/Business Branding/Accessory Design</option>
                  <option>Grant/Proposal/Technical Writing</option>
                  <option>All Services</option>
                  <option>General Questions</option>
                  <option>Other</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Message <span style={{ color: 'red' }}>*</span></Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  maxLength="6000"
                  isInvalid={!!errors.description}
                  style={{ height: '200px' }}
                />
                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
              </Form.Group>
              <div className="contact-buttons">
                <Button variant="contained" type="submit" className='contact-buttons-primary'>Submit</Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.926889027408!2d-89.69818928473818!3d39.75887997944432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8875190c3f9de237%3A0x47962c87d15bc396!2s2501%20Chatham%20Rd%20Ste%20N%2C%20Springfield%2C%20IL%2062704%2C%20USA!5e0!3m2!1sen!2sin!4v1622473271938!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>
      <Box className="subscribe-section">
        <Container>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6} className="d-flex justify-content-start">
              <img src={logo} alt="STRATAGEMIX" className="subscribe-logo" />
            </Grid>
            <Grid item xs={12} md={6}>
              <form ref={formRef} className="subscribe-form" action='https://docs.google.com/forms/d/e/1FAIpQLSdxkaBNsPMTct62p250JtW0eOOITluOw4JaxqW5PPKrKQInjg/formResponse' method="POST" target="_blank" onSubmit={handleSubscribeSubmit}>
                <Typography variant="h4" className="subscribe-title">SUBSCRIBE</Typography>
                <input className="subscribe-input" type="email" name="entry.557918144" placeholder="Email" required aria-label="Email" />
                <Button
                  variant="contained"
                  type="submit"
                  className="subscribe-button"
                >
                  SUBSCRIBE
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Contact;
